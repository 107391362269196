.old-paper {
    background: rgb(255, 252, 241);
    background: linear-gradient(
        329deg,
        rgba(255, 252, 241, 1) 0%,
        rgba(255, 252, 245, 1) 35%,
        rgba(250, 255, 240, 1) 100%
    );
}

.card-view {
    .media-container {
        width: 100%;
        height: 100px;
    }
}

.a {
    background: linear-gradient(
        16deg,
        rgba(167, 255, 235, 1) 0%,
        rgba(240, 244, 195, 1) 35%,
        rgba(255, 224, 178, 1) 100%
    );
}
.b {
    background: rgb(255, 205, 210);
    background: linear-gradient(
        16deg,
        rgba(255, 205, 210, 1) 0%,
        rgba(209, 196, 233, 1) 35%,
        rgba(179, 229, 252, 1) 100%
    );
}
.c {
    background: rgb(174, 213, 129);
    background: linear-gradient(
        329deg,
        rgba(174, 213, 129, 1) 0%,
        rgba(205, 220, 57, 1) 35%,
        rgba(215, 204, 200, 1) 100%
    );
}
