.profile {
    background-color: aquamarine;
    height: 100%;

    .full-width-separator {
        height: 20px;
    }

    .back {
        position: relative;
    }

    .overlay {
        position: absolute;
        bottom: 80px;
        right: 20px;
    }

    .footer {
        min-height: 100px;
        background: rgb(0, 0, 0);
        background: linear-gradient(
            270deg,
            rgba(135, 124, 106, 1) 0%,
            rgba(97, 86, 67, 1) 34%,
            rgba(92, 71, 54, 1) 100%
        );
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: white;
        padding-bottom: 30px;

        .goto-links {
            padding-left: 4%;
            padding-right: 4%;
            padding-top: 10px;
            display: flex;
            flex: auto;
            flex-wrap: wrap;
            flex-direction: row;
        }

        .links {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            flex: auto;
            padding-top: 10px;
            padding-left: 4%;
            padding-right: 4%;
            text-align: center;
            .link-image {
                width: 64px;
                height: 64px;
                margin: 10px;
                // border: 1px dotted grey;
                // border-radius: 10px;
                padding: 4px;
                opacity: 0.5;
            }
        }
    }

    /////////////////////////////////////////////// LG SCREENS ///////////////////////////////////////////////

    .container {
        margin: auto;
        padding: 10px;
        padding-top: 20px;
        position: relative;
        height: 680px;

        &.focused {
            padding: 5%;

            .blur {
                background: url("./images/artworks-lg.jpeg") no-repeat center;
                background-size: cover;
                // -webkit-filter: blur(2px);
                // -moz-filter: blur(2px);
                // -o-filter: blur(2px);
                // -ms-filter: blur(2px);
                // filter: blur(2px);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
            }
        }

        &.nopadding {
            padding: 10px;
        }

        .player-wrapper {
            display: inline;
        }

        .text-container {
            position: absolute;
            border-radius: 20px;
            padding: 10px;
            background-color: rgb(0, 0, 0, 0.2);
            backdrop-filter: blur(15px);
            max-width: 450px;

            &.odd {
                left: 10vw;
            }

            &.even {
                right: 10vw;
            }

            &.mid {
                right: 5vw;
            }
        }

        .other-container {
            background-color: rgb(0, 0, 0, 0.2);
            backdrop-filter: blur(15px);
            border-radius: 20px;
            padding: 10px;
            max-width: unset;
            height: 100%;
            margin: 0;
        }

        .text {
            margin: 20px;
            margin-left: 0;
            color: white;
        }
    }

    .intro-container {
        background: url("./images/with-krishna-lg.jpeg");
        background-repeat: no-repeat;
        background-position: 25% 25%;
        background-size: cover;
    }

    .cat-comm-container {
        background: url("./images/samarpita-lg.jpeg");
        background-repeat: no-repeat;
        background-position: 25% 25%;
        background-size: cover;
    }

    .cat-int-container {
        background: url("./images/baby-rama-lg.jpeg");
        background-repeat: no-repeat;
        background-position: 25% 25%;
        background-size: cover;
    }

    .cat-buddha-container {
        background: url("./images/buddha-lg.jpeg");
        background-repeat: no-repeat;
        background-position: 25% 25%;
        background-size: cover;
    }

    .cat-artworks-container {
        background: url("./images/artwork-lg.jpeg");
        background-repeat: no-repeat;
        background-position: 25% 25%;
        background-size: cover;
        padding: 0;
    }

    /////////////////////////////////////////////// MD SCREENS ///////////////////////////////////////////////

    @media screen and (max-width: 980px) {
        .intro-container {
            background: url("./images/with-krishna-md.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
        }

        .cat-comm-container {
            background: url("./images/samarpita-md.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
            height: 400px;
        }

        .cat-int-container {
            background: url("./images/baby-rama-md.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
            height: 400px;
        }

        .cat-buddha-container {
            background: url("./images/buddha-md.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
            height: 400px;
        }

        .cat-artworks-container {
            background: url("./images/artwork-md.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
            height: 400px;
        }

        .mid {
            left: 5vw;
        }
    }

    /////////////////////////////////////////////// SM SCREENS ///////////////////////////////////////////////

    @media screen and (max-width: 480px) {
        .intro-container {
            background: url("./images/with-krishna-sm.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
        }

        .cat-comm-container {
            background: url("./images/samarpita-sm.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
            height: 400px;
        }

        .cat-int-container {
            background: url("./images/baby-rama-sm.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
            height: 400px;
        }

        .cat-buddha-container {
            background: url("./images/buddha-sm.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 97%;
            background-size: 169%;
            height: 400px;
        }

        .cat-artworks-container {
            background: url("./images/artwork-sm.jpeg");
            background-repeat: no-repeat;
            background-position: 25% 25%;
            background-size: cover;
            height: 500px;
        }

        .mid {
            left: 5vw;
        }
    }
}
