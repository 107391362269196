@import url(https://fonts.googleapis.com/css?family=Dancing+Script);

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: red;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h4,
h5 {
    text-align: center;
    color: #ed6c02;
    font-family: 'Dancing Script', sans-serif;
}

/* .page-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 80px;
    color: #ed6c02;
    font-family: 'Dancing Script', sans-serif;
} */
