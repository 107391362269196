.page-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 80px;
    color: #ed6c02;
    font-family: 'Dancing Script', sans-serif;

    h1 {
        margin-top: 0;
    }
}
