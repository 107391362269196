.payment-section {
    padding: 20px;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 10px;

    .qr-code-container {
        .qr-code {
            height: 300px;
            width: 300px;
        }
    }

    .pay-button-area {
        padding: 20px;
    }

    .form-check {
        label {
            margin-left: 10px;
        }

        .form-check-input {
            float: unset;
        }
    }
}
