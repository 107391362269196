.showcase {
    .images {
        line-height: 0;

        .image-container {
            display: inline-block;
            margin: 5px;
            width: calc(100vw / 8);
            min-width: 100px;
            min-height: 100px;
            max-width: 300px;
            max-height: 300px;
            border-radius: 5px;
            box-shadow: 6px 6px 6px grey;
            cursor: pointer;

            .image {
                width: 100%;
                aspect-ratio: 1/1;
                max-width: 300px;
                max-height: 300px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }

    @media screen and (max-width: 1150px) {
        .images {
            .image-container {
                width: 18%;
            }
        }
    }

    @media screen and (max-width: 680px) {
        .images {
            .image-container {
                width: 31%;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .images {
            .image-container {
                width: 45%;
            }
        }
    }

    .title {
        color: white;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
    }

    .gallery-button {
        height: 150px;
        width: 100%;
        background-color: grey;
    }

    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 10px;
    height: 100%;
}
