.painting-photos {
    .thumbnail-area {
        .thumbnail-container {
            max-width: 20vw;
            margin: 10px;
            cursor: pointer;

            img {
                max-height: 100%;
                max-width: 100%;
            }

            &.transparent {
                opacity: 0.4;
            }
        }
    }
}
